html {
  overflow-wrap: break-word;
  touch-action: manipulation;
}

:root {
  --base-size: #{$base-size};
  --primary: #{$primary};
  --primary-invert: #{$primary-invert};
  --secondary: #{$link};
  --secondary-invert: #{$link-invert};
  --text: #363636;
  --text-invert: #{$text-invert};
  --grey: #717171;
  --grey-invert: #{findColorInvert($grey)};
  --background: #{$body-background-color};
  --background-invert: #{$background-invert};
  --logo-width: #{$logo-width};

  --transition: 0.15s linear;
  --small-gutter: 8px;

  --depth-4: 0px 1.6px 3.6px rgba(101, 115, 129, 0.13);
  --depth-8: 0px 3.2px 7.2px rgba(101, 115, 129, 0.13);
  --depth-16: 0px 6.4px 14.4px rgba(101, 115, 129, 0.13);
  --depth-64: 0px 25.6px 57.6px rgba(101, 115, 129, 0.22);
  --depth-16-green: 0px 6.4px 14.4px rgba(2, 170, 176, 0.13);

  --round-4: 4px;
  --round-6: 6px;
  --round-8: 8px;
  --round-12: 12px;
  --round-16: 16px;

  --danger-100: #a4262c;
  --danger-50: #f2a9aa;
  --danger-30: #ebaaad;
  --danger: #EB5757;

  --warning-100: #f2994a;
  --warning-30: #fef5ed;

  --success-30: #eaf7f0;
  --success-50: #6fcf97;
  --success-100: #219653;

  //--secondary-100: #5bb3e4;
  --secondary-100: #0B92F1;
  --secondary-100-dark: #3b5e72;
  --secondary-100-darker: #0B92F1;
  //--secondary-50: #add9f2;
  --secondary-50: #8DC8F8;
  --secondary-50-dark: #a1cae1;
  --secondary-50-darker: #98bfd5;
  --secondary-30: #eff7fc;
  --secondary-30-dark: #e8eff4;
  --secondary-30-darker: #e3eaef;
  --secondary-10: #EFF7FC;

  --indigo-10: #232b36;
  --indigo-9: #464f5b;
  --indigo-8: #657381;
  --indigo-7: #939eab;
  --indigo-6: #c5cdd5;
  --indigo-5: #d2d7de;
  --indigo-4: #e0e3e9;
  --indigo-3: #eaecf0;
  --indigo-2: #f4f6f8;
  --indigo-1: #f9fafb;

  --gray-9: #282828;
  --gray-8: #363636;
  --gray-7: #5b5b5b;
  --gray-6: #818181;
  --gray-5: #ababab;
  --gray-4: #d5d5d5;
  --gray-3: #e8e8e8;
  --gray-2: #f8f8f8;
  --gray-1: #fbfbfb;

  --blue-1: #2F80ED;
  --blue-2: #2D9CDB;
  --blue-3: #56CCF2;

  --black-100: #000000;
  --white-100: #ffffff;
}
