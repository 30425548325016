// Customize bulma
@import '~bulma/sass/utilities/_all';

// Font
@import 'font';

// Import selless config
@import 'config';

// Update Bulma's global variables
$family-sans-serif: "Poppins", sans-serif;
$family-primary: "Poppins", sans-serif;
$family-secondary: "Raleway", sans-serif;

$primary: $primary-color;
$primary-invert: findColorInvert($primary);
$text: $text-color;
$text-invert: findColorInvert($text);
$link: $secondary-color;
$link-invert: findColorInvert($link);

$colors: (
  "white": ($white, $black),
  "black": ($black, $white),
  "light": ($light, $light-invert),
  "dark": ($dark, $dark-invert),
  "primary": ($primary, $primary-invert),
  "link": ($link, $link-invert),
  "info": ($info, $info-invert),
  "success": ($success, $success-invert),
  "warning": ($warning, $warning-invert),
  "danger": ($danger, $danger-invert),
);

$background: $background-color;
$background-invert: findColorInvert($background);
$body-background-color: $background;

$body-font-size: 15px;
$body-line-height: 24px;

// Update some of Bulma's component variables
$control-border-width: 1px;
$label-weight: 500;

@import "~bulma";
