.container {
  padding-right: calc(3 * #{var(--base-size)});
  padding-left: calc(3 * #{var(--base-size)});

  @media screen and (min-width: 1024px) {
    max-width: 960px;
  }

  &.is-fullwidth {
    max-width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-right: calc(3 * #{var(--base-size)});
    padding-left: calc(3 * #{var(--base-size)});
  }
}

.section {
  // stylelint-disable-next-line at-rule-no-unknown
  @extend .container;

  @media screen and (max-width: 768px) {
    padding-top: 48px !important;
    padding-bottom: 48px !important;
  }

  &--small {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &--medium {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  &--large {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

.page {
  display: flex;
  flex-direction: column;

  > .loading-component {
    height: 100vh;
  }

  &__content-wrapper {
    flex: 1;
  }

  &__content {
    margin-top: 0;
    min-height: 100vh;
  }
}

.wrapper {
  background-color: #fff;

  &--primary {
    background-color: var(--background);
  }
}
