@import 'default';
@import 'variable';
@import 'helper';
@import 'global';
@import 'components';
@import 'animate';
@import '~bulma-steps';

@import '~swiper/components/pagination/pagination.scss';
@import '~swiper/swiper.scss';

.input[disabled] {
  -webkit-text-fill-color: var(--gray-7);
  opacity: 1;
  border: none;
  background: var(--indigo-2)
}

.break-flex {
  flex-basis: 100%;
  height: 0;
}

.delete {
  height: 40px;
  max-height: 40px;
  width: 40px;
  max-width: 40px;

  background-color: var(--indigo-2);

  &::before {
    width: 37%;
    height: 3px;
    background: var(--indigo-7);
  }

  &::after {
    height: 37%;
    width: 3px;
    background: var(--indigo-7);
  }
}

.container {
  max-width: 1120px;
}

.m-top {
  margin-top: 5%;
  @media screen and (max-width: 768px) {
    margin-top: 15%;
  }
}

.m-bot {
  margin-bottom: 5%;
  @media screen and (max-width: 768px) {
    margin-bottom: 15%;
  }
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.has-pointer {
  cursor: pointer;
}

.is-link {
  cursor: pointer;
  color: var(--secondary-100);
}

.is-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.text-large-ex {
  font-size: 24px;
  line-height: 32px;
}

.text-large {
  font-size: 15px;
  line-height: 32px;
}

.text-normal {
  font-size: 12px;
  line-height: 24px;
}

.image {
  &.is20x20 {
    width: 20px;
    height: 20px;
  }
  &.is40x40 {
    width: 40px;
    height: 40px;
  }
  &.is278x270 {
    width: 270px;
    height: 270px;
  }
  &.is180x180 {
    width: 180px;
    height: 180px;
  }
  &.is56x32 {
    width: 56px;
    height: 32px;
  }
  &.is14x14 {
    width: 14px;
    height: 14px;
  }
  &.is176x40 {
    width: 176px;
    height: 40px;
  }
  &.is160x160 {
    width: 160px;
    height: 160px;
  }
  &.is180x180 {
    width: 180px;
    height: 180px;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--white-100);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--indigo-4);
  border-radius: var(--round-4);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--indigo-6);
}
