.tracking-form-page {
  min-height: 100px;

  .is-flex.space-between {
    justify-content: space-between;
    align-items: center;
  }

  .is-link {
    color: #2d9cdb;
    font-weight: 600;
  }

  .is-hyperlink {
    color: #2d9cdb;
    text-decoration: underline;
    font-weight: 600;
  }

  .contact-form__row {
    flex-direction: row;
  }

  .contact-form__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3rem;
  }

  .form__subtitle {
    margin-top: 0.8rem;
    margin-bottom: 3rem;
    max-width: 500px;
  }

  .contact-form__item {
    margin-bottom: 2rem;
    line-height: 1.5rem;
  }

  .notification.is-danger.is-light {
    background-color: #feecf0;
    color: #cc0f35;
  }

  .notification.is-success.is-light {
    background-color: #effaf3;
    color: #257942;
  }

  a.is-link {
    color: #2d9cdb !important;
  }

  button.button--wide {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.contact-form {
  max-width: 720px;
  margin: auto;

  .notification.is-danger.is-light {
    background-color: #feecf0;
    color: #cc0f35;
  }

  .field {
    margin-bottom: 2rem;

    label.label {
      font-weight: 500;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .address-form .field {
    margin-bottom: 15px;
  }

  .select {
    height: 3.5rem;

    select {
      height: 100%;
      padding-left: 1rem;

      &.placeholder {
        color: #b5b5b5;
      }
    }

    &::after {
      border-color: var(--text) !important;
      opacity: 0.6 !important;
      border-bottom-width: 2px !important;
      border-left-width: 2px !important;
    }
  }

  .file-uploads {
    padding: 2rem 1rem;
    border: 1px solid #dbdbdb;
    cursor: pointer;
    border-radius: 0.25rem;
    width: 100%;

    label[for='file'] {
      cursor: pointer;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
  }

  .button--cta {
    min-width: 150px;
  }

  &__row {
    flex-direction: row;
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3rem;
  }

  &__item {
    margin-bottom: 2rem;
    line-height: 1.5rem;
  }
}

.order-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #ececec;
  border-radius: 4px;
  transition: 0.3s all ease;
  cursor: pointer;
  margin-top: 0.5rem;

  &__checkbox {
    width: 1rem;
  }

  &__image {
    width: 4.5rem;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .product-title {
    font-weight: 500;
  }

  .properties {
    display: flex;
    flex-direction: row-wrap;
    font-size: 0.825rem;

    .property {
      margin-right: 1rem;
    }
  }

  &__qty {
    margin-left: auto;
    min-width: 1.5rem;
    text-align: right;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .order-row {
    .product-title {
      font-size: 0.75rem;
    }

    .properties {
      font-size: 0.625rem;
    }
  }

  .order-form__row {
    display: flex;
    flex-direction: column-reverse;
  }

  .contact-form {
    .buttons {
      display: flex;
      flex-direction: column-reverse;

      .button.is-fullwidth-mobile {
        display: block;
      }

      .button:not(:last-child):not(.is-fullwidth) {
        margin-right: 0;
      }
    }
  }
}
